import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  CallUs,
  BannerLinks,
  TextBox
} from '../components'

import contacts from '../components/CallUs/DefaultContacts'

import BannerImage from '../assets/impressum.jpg'
import JoinTheTeam from '../assets/bannerlinks/jointheteam.jpg'
import TakeOff from '../assets/bannerlinks/takeoff.jpg'

const boxes = [
  {
    text: 'Datenschutz',
    image: JoinTheTeam,
    link: '/datenschutzerklaerung',
    linkText: 'Mehr lesen'
  },
  {
    text: 'Join the team!',
    image: TakeOff,
    link: '/karriere',
    linkText: 'Mehr lesen'
  }
]

const Impressum = () => (
  <Layout>
    <Helmet
      title="Impressum holzweg GmbH®"
      meta={[
        {
          name: 'description',
          content:
            'Impressum der holzweg GmbH® aus Innsbruck - Offenlegung gemäß § 5 Abs.1 nach österreichischem E-Commerce Gesetzes'
        },
        {
          name: 'keywords',
          content: 'Impressum, holzweg, Innsbruck, E-Commerce, Gesetz, Medieninhaber, Adresse, Firmenbuchnummer, UID, Bank, Steuernummer'
        },
        {
          property: 'og:description',
          content:
            'Impressum der holzweg GmbH® aus Innsbruck - Offenlegung gemäß § 5 Abs.1 nach österreichischem E-Commerce Gesetzes'
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/impressum-57e2b6a898328735d5e7650049000b57.jpg'
        }
      ]}>
    </Helmet>
    <BannerSmall image={BannerImage} title="Law and order" />
    <TextBox
      title="Impressum"
      text={
        <span>
          <p>Offenlegung gemäß § 5 Abs.1 nach österreichischem E-Commerce Gesetzes</p>
          <p><b>Medieninhaber dieser Website</b><br />holzweg GmbH<br />Adamgasse 11, 3. Stock<br />A-6020 Innsbruck<br />Telefon: <a href="tel:004369917407403">0043 (0)699 174 074-03</a><br />E-Mail: <a href="mailto:office@holzweg.com?subject=Mail%von%holzweg.com">office@holzweg.com</a><br /><a href="https://www.holzweg.com" target="_blank">www.holzweg.com</a>
          </p>
          <p><b>Firmenbuchnummer</b><br />FN423141t</p>
          <p><b>UID-Nummer</b><br />ATU69192759</p>
          <p><b>Steuernummer</b><br />81/3258928</p>
          <p><b>Bankverbindung</b><br />BTV-Innsbruck<br />BLZ 16370<br />Kontonummer: 137-110164<br />BIC: BTVAAT22<br />IBAN: AT361637000137110164</p>
          <p><b>Offenlegung nach Mediengesetz:</b><br />Bezirkshauptmannschaft Innsbruck<br />Unternehmensgegenstand: IKT-Consulting, Internetmarketing, E-Commerce und Softwarelösungen<br />Geschäftsführer: Georg Bader, MSc
          </p>
          <p><b>Mitglied der Wirtschaftskammer Tirol</b><br />FG Unternehmensberatung und Informationstechnologie<br />FG Werbung und Marktkommunikation<br /><br />Die österreichische Gewerbeordnung 1994 kann unter <a href="http://www.ris.bka.gv.at/" target="_blank">www.ris.bka.gv.at</a> aufgerufen werden.
          </p>
          <p><b>Gerichtsstand Innsbruck</b></p>
          <p><b>Konzeption & Design</b><br />holzweg GmbH<br /><br />Für holzweg Kund:innen gelten die holzweg Richtlinien sowie die <a href="/downloads/IT-AGBs.pdf" target="_blank">AGBs der IT-Dienstleister</a>.</p>
          <p><b>Copyright</b></p>
          <p>Inhalt, Struktur und Layout der Website holzweg.com sind urheberrechtlich geschützt. Urheberrechte für Bildmaterial, Texte und andere geschützte Werke, Marken und Kennzeichen werden dadurch nicht beschnitten. Zur persönlichen, nicht kommerziellen Nutzung, darf der dargestellte Inhalt heruntergeladen, gedruckt und verteilt werden, wenn der Inhalt unverändert bleibt und die Quelle angegeben wird (Quelle: holzweg GmbH). Jede andere Verwendung der auf dieser Website verfügbaren Inhalte zu einem anderen als dem oben genannten Zweck ist ohne ausdrückliche, schriftliche Zustimmung des jeweiligen Urhebers untersagt.</p>
          <p><b>Disclaimer</b></p>
          <p>Nach den allgemeinen Gesetzen ist der Betreiber einer Seite für dessen Inhalt verantwortlich. Die Inhalte dieser Website wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der auf dieser Website dargelegten einzelnen Seiten wird jedoch keine Haftung übernommen.<br />Die Website enthält unter anderem Links zu Websites von Dritten, auf deren Inhalt wir keinen Einfluss nehmen können und wir daher für deren Inhalt auch keine Gewähr übernehmen können. Die verlinkten Seiten wurden zum Zeitpunkt der Linksetzung auf mögliche Rechtsverstöße geprüft. Eine permanente Kontrolle der verlinkten Websites ist jedoch wegen dem dauernden Wandel der Websites und der umfangreichen Linksammlung ohne konkreten Anhaltspunkt nicht zumutbar. Bei bekannt werden von Rechtsverletzungen werden anstößige Links umgehend von uns entfernt.</p>
      </span>
      }
    />
    <CallUs contacts={contacts} title="Kontakte" />
    <BannerLinks boxes={boxes} />
  </Layout>
)

export default Impressum
